export default {
  firebase: {
    apiKey: "AIzaSyDVUL5h8Kohf9oOM96UmoIECRoakhJV1iU",
    authDomain: "clipclop-273921.firebaseapp.com",
    projectId: "clipclop-273921",
    storageBucket: "clipclop-273921.appspot.com",
    messagingSenderId: "996386297409",
    appId: "1:996386297409:web:668b712ba222ef020cd8c0"
  },
}
