import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'
import config from './config'

firebase.initializeApp(config.firebase)
firebase.analytics()

export const auth = firebase.auth()
export const rawStorage = firebase.storage()
export const performance = firebase.performance()
export const firestore = firebase.firestore()
if (process.env.NODE_ENV === 'development') {
  auth.useEmulator('http://localhost:9099')
  rawStorage.useEmulator('http://localhost', 9199)
  firestore.useEmulator('localhost', 8080)
}

export const storage = rawStorage.ref()

export default firebase
